import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaUser } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { toast } from "react-toastify";

import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../slices/gymSlice";
import { logout } from "../slices/authSlice";

const Header = function () {
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApi] = useLogoutMutation();
  let gymId;
  if (userInfo) {
    gymId = userInfo.gymId;
  }

  const handleLogout = async () => {
    try {
      await logoutApi().unwrap();
      dispatch(logout());

      navigate("/login");
      toast.success("Successfully logged out");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <header style={{ position: "sticky", top: "0px", zIndex: 1 }}>
      <Navbar
        bg="primary"
        variant="dark"
        expand="md"
        collapseOnSelect
        style={{ height: "55px", marginTop: "0.2px" }}
      >
        <Container style={{ marginRight: "8%" }}>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img src={logo} alt="zpotter"></img>
              {"  "}
              {userInfo ? userInfo?.gymId?.name : "zpotter"}
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="ms-auto"
              style={{ backgroundColor: "rgb(60, 76, 93)" }}
            >
              {userInfo ? (
                <>
                  <LinkContainer to="/whatsAppAds">
                    <Nav.Link>WhatsApp Ads</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/">
                    <Nav.Link>Customers Details</Nav.Link>
                  </LinkContainer>

                  <LinkContainer to="/availableGyms">
                    <Nav.Link>Gyms</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/paymentHistory">
                    <Nav.Link>Payment History</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/pendingPayments">
                    <Nav.Link>Pending Payments</Nav.Link>
                  </LinkContainer>
                </>
              ) : (
                <></>
              )}

              {userInfo ? (
                <>
                  <NavDropdown title={userInfo.firstName}>
                    <NavDropdown.Item>
                      <LinkContainer style={{ color: "grey" }} to="/gymDetails">
                        <Nav.Link>Gyms</Nav.Link>
                      </LinkContainer>
                    </NavDropdown.Item>{" "}
                    <NavDropdown.Item onClick={handleLogout}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <LinkContainer to="/login">
                  <Nav.Link>
                    <FaUser />
                    Login
                  </Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
