import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  ListGroup,
  Card,
  Table,
  Form,
  Button,
  Badge,
} from "react-bootstrap";
import { useCreateWhatsAppAdsMutation } from "../slices/wsMessageSlice";
import { toast } from "react-toastify";
import VerticallyCenteredModal from "../components/SubmitModal";
import Loader from "../components/Loader";
import { useGetWhatsAppAdsQuery } from "../slices/wsMessageSlice";
import { useUpdateWhatsAppAdsMutation } from "../slices/wsMessageSlice";
import { getCurrentDate, getCurrentTime } from "../utils/getCurrentDate";
import { setCredentials } from "../slices/authSlice";

const WhatsAppAdsScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [promotionName, setPromotionName] = useState("");
  const [promotionMessage, setPromotionMessage] = useState("");
  const [recipientMobiles, setRecipientMobiles] = useState("");
  const [recipientMobilesArray, setRecipientMobilesArray] = useState([""]);
  const [sheduledTime, setSheduledTime] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [whatsAppBalance, setWhatsAppBalance] = useState(0);
  const [selectedAd, setSelectedAd] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [editEnabled, setEditEnabled] = useState("");
  const [unSentAds, setUnSentAds] = useState([]);
  const [confirmPromotionName, setConfirmPromotionName] = useState();
  const [sentAds, setSentAds] = useState([]);
  const [startDate, setStartDate] = useState(
    userInfo?.whatsAppAdStartDate
      ? userInfo?.whatsAppAdStartDate
      : getCurrentDate()
  );

  const [endDate, setEndDate] = useState(
    userInfo?.whatsAppAdStartDate
      ? userInfo?.whatsAppAdEndDate
      : getCurrentDate()
  );

  const [getApiQuery, setGetApiQuery] = useState(
    `createdAt[gte]=${startDate}T00:00:00.001Z&createdAt[lte]=${endDate}T23:00:00.001Z`
  );

  let {
    data: whatsAppAds,
    isLoading,
    error,
    refetch,
  } = useGetWhatsAppAdsQuery({
    token: userInfo?.token,
    query: getApiQuery,
  });

  const [updateWhatsAppAdStatus] = useUpdateWhatsAppAdsMutation();

  const hanbdleSubmit = async () => {
    if (selectedAd?.promotionName === confirmPromotionName) setModalShow(true);
    else toast.error("Campaign Name and Confirm name are not matching");
  };

  useEffect(() => {
    refetch();
    const ads = isLoading ? [] : whatsAppAds ? whatsAppAds : [];
    const filteredArray1 = ads?.filter((obj) => obj.isMessageSent !== true);
    const filteredArray2 = ads?.filter((obj) => obj.isMessageSent === true);
    setUnSentAds(filteredArray1);
    setSentAds(filteredArray2);
  }, [refetch, whatsAppAds, getApiQuery]);

  const handleUpdateMessage = async () => {
    try {
      const res = await updateWhatsAppAdStatus({
        payload: {
          _id: selectedAd?._id,
          isMessageSent: true,
        },
        token: userInfo.token,
      }).unwrap();
      toast.success(res?.Message);
      const filteredArray1 = unSentAds?.filter(
        (obj) => obj._id !== selectedAd._id
      );
      setConfirmPromotionName("");
      setUnSentAds(filteredArray1);
      setSentAds([...sentAds, selectedAd]);
      setSelectedAd({
        _id: "",
        promotionName: "",
        promotionMessage: "",
        recipientMobiles: "",
      });
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };

  const dispatch = useDispatch();
  const handleGetAds = async () => {
    setGetApiQuery(
      `createdAt[gte]=${startDate}T00:00:00.001Z&createdAt[lte]=${endDate}T23:00:00.001Z`
    );
    dispatch(
      setCredentials({
        ...userInfo,
        whatsAppAdStartDate: startDate,
        whatsAppAdEndDate: endDate,
      })
    );
  };

  return (
    <>
      {" "}
      <Row
        className="mb-2"
        style={{
          margin: "auto",
          width: "95%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-23"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            ></Form.Control>{" "}
          </Form.Group>
        </Col>
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-27"
              value={endDate?.split("T")[0]}
              onChange={(e) => setEndDate(e.target.value)}
            ></Form.Control>{" "}
          </Form.Group>
        </Col>
        <Col xs={6} md="auto" sm={6}>
          {" "}
          <Button onClick={handleGetAds}>Get Ads</Button>
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          border: "solid",
          borderWidth: "1px",
          backgroundColor: "#ecf3f2",
          borderRadius: "10px",
        }}
      >
        <VerticallyCenteredModal
          title="Are you sure want to submit this ?"
          show={modalShow}
          size="sm"
          onHide={() => setModalShow(false)}
          onSubmit={handleUpdateMessage}
          setEdit={setEditEnabled}
        />
        {isLoading ? (
          <Loader />
        ) : error ? (
          <h3> {error?.data?.error}</h3>
        ) : (
          <>
            <div
              style={{
                width: "300px",
                backgroundColor: "#ecf3f2",
                margin: "10px",
                border: "solid",
                borderWidth: "0.7px",
                borderRadius: "10px",
              }}
            >
              <div>
                <h6
                  style={{
                    color: "orange",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  <strong>
                    {" "}
                    Not Sent Messages {unSentAds && unSentAds.length}
                  </strong>
                </h6>
                <div style={{ height: "50vh", overflowY: "auto" }}>
                  {unSentAds?.map((wa) => (
                    <li
                      key={wa._id}
                      style={{
                        listStyleType: "none",
                        margin: "5px",
                        height: "30px",
                      }}
                    >
                      <button
                        onClick={(e) => setSelectedAd(wa)}
                        style={{
                          width: "100%",
                          height: "27px",
                          borderRadius: "5px",
                          borderWidth: "1px",
                        }}
                      >
                        {wa.promotionName}
                      </button>
                    </li>
                  ))}
                </div>
              </div>
              <div>
                <h6
                  style={{
                    color: "green",
                    textAlign: "center",
                    marginTop: "15px",
                    borderTop: "solid",
                    paddingTop: "10px",
                  }}
                >
                  <strong>Sent Messages {sentAds && sentAds.length}</strong>
                </h6>
                <div style={{ height: "50vh", overflowY: "auto" }}>
                  {sentAds?.map((wa) => (
                    <li
                      key={wa._id}
                      style={{
                        listStyleType: "none",
                        margin: "5px",
                        height: "30px",
                      }}
                    >
                      <button
                        onClick={(e) => setSelectedAd(wa)}
                        style={{
                          width: "100%",
                          height: "27px",
                          borderRadius: "5px",
                          borderWidth: "1px",
                        }}
                      >
                        {wa.promotionName}
                      </button>
                    </li>
                  ))}
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor:
                  selectedAd?.currentWhatsAppBalance <
                  selectedAd?.recipientMobiles?.length
                    ? "red"
                    : "#ecf3f2",
              }}
            >
              <h5>
                WhatsApp Balance Count :&nbsp;&nbsp;
                <Badge>
                  {selectedAd?.currentWhatsAppBalance
                    ? selectedAd?.currentWhatsAppBalance
                    : 0}{" "}
                </Badge>
                &nbsp;&nbsp;Message Count :{" "}
                <Badge>{selectedAd?.recipientMobiles?.length}</Badge>
              </h5>
              <Row className="my-2">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Form.Label>Campaign Name :&nbsp;&nbsp; </Form.Label>{" "}
                  <Form.Control
                    style={{ width: "40%" }}
                    value={selectedAd?.promotionName}
                  ></Form.Control>
                  &nbsp;&nbsp;
                  <Form.Control
                    style={{ width: "40%" }}
                    value={confirmPromotionName}
                    onChange={(e) => setConfirmPromotionName(e.target.value)}
                  ></Form.Control>
                </div>
              </Row>
              <Row className="my-2">
                <Col md={4}>
                  <Card>
                    <Form.Control
                      as="textarea"
                      value={selectedAd?.recipientMobiles}
                      style={{
                        height: "300px",
                        width: "100%",
                        overflowY: "scroll", // Enable vertical scrolling
                        resize: "none", // Prevent resizing (optional)
                      }}
                    />
                  </Card>
                </Col>
                <Col md={8}>
                  <Card>
                    {" "}
                    <Form.Control
                      as="textarea"
                      value={selectedAd?.promotionMessage}
                      style={{
                        height: "300px",
                        width: "100%",
                        overflowY: "scroll", // Enable vertical scrolling
                        resize: "none", // Prevent resizing (optional)
                      }}
                    />
                  </Card>
                </Col>
              </Row>
              <Row className="my-3">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Form.Control
                    type="file"
                    style={{ margin: "5px", width: "300px" }}
                    // onChange={handleFileChange} // Function to handle file changes
                  />

                  <Form.Control
                    type="file"
                    style={{ margin: "5px", width: "300px" }}
                    // onChange={handleFileChange} // Function to handle file changes
                  />

                  <Form.Control
                    type="file"
                    style={{ margin: "5px", width: "300px" }}
                    // onChange={handleFileChange} // Function to handle file changes
                  />
                </div>
              </Row>{" "}
              <br />
              <Row className="my-3">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={hanbdleSubmit}
                    disabled={
                      !selectedAd ||
                      (selectedAd._id === "" ? true : false) ||
                      selectedAd?.currentWhatsAppBalance <
                        selectedAd?.recipientMobiles?.length
                    }
                    className=" marginPointOne sixteenWidth buttonOrange"
                  >
                    Mark as Sent
                  </Button>
                </div>
              </Row>
              <br />
              <Row className="my-2">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p>
                    <strong>Note :</strong> All Campaign will be process between
                    10:00 AM to 6:00 PM working days.If you send any spam or
                    abusing messages or personal message then your credit will
                    suspended and legal action will be taken .
                  </p>
                </div>
              </Row>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default WhatsAppAdsScreen;
