import { React, useEffect, useState } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { Pencil, CheckLg, XLg } from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";

import Loader from "../components/Loader";
import Message from "../components/Message";
import VerticallyCenteredModal from "../components/SubmitModal";
import FieldWarning from "../components/FieldWarning";
import { setCredentials } from "../slices/authSlice";

import { toast } from "react-toastify";
import { getCurrentDate, getCurrentTime } from "../utils/getCurrentDate";
import {
  useGetCustomersQuery,
  useUpdateCustomerMutation,
} from "../slices/customerSlice";
import { useGetAllGymsQuery } from "../slices/gymSlice";

const GymDetails = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [editEnabled, setEditEnabled] = useState("");

  const [gymName, setGymName] = useState("");
  const [gymId, setGymId] = useState();
  const [gymLocation, setGymLocation] = useState("");
  const [mobileNumber, setMobileNumber] = useState();
  const [secondaryNumber, setSecondaryNumber] = useState();
  const [pincode, setPincode] = useState();
  const [contactedDate, setContactedDate] = useState(getCurrentDate());
  const [reachedThrough, setReachedThrough] = useState("");
  const [notes, setNotes] = useState("");
  const [status, setStatus] = useState("");

  const [gymNamePresent, setGymNamePresent] = useState(false);
  const [mobileNumberPresent, setMobileNumberPresent] = useState(false);
  const [secondaryNumberPresent, setSecondaryNumberPresent] = useState(false);
  const [gymIdPresent, setGymIdPresent] = useState();
  const [pincodePresent, setPincodePresent] = useState(false);
  const [contactedDatePresent, setContactedDatePresent] = useState(false);
  const [reachedThroughPresent, setReachedThroughPresent] = useState(false);
  const [notesPresent, setNotesPresent] = useState(false);
  const [statusPresent, setStatusPresent] = useState(false);
  const [filterSelect, setFilterSelect] = useState("Date Filter");
  const filterSelectOption = ["Date Filter", "Pincode Filter"];

  const [modalShow, setModalShow] = useState(false);
  const [filterValue, setFilterValue] = useState(`&status=Not Contacted`);
  const [filtValue, setFiltValue] = useState(`&status=Not Contacted`);
  const [startPincode, setStartPincode] = useState(
    userInfo?.customersStartPincode ? userInfo?.customersStartPincode : 600001
  );
  const [endPincode, setEndPincode] = useState(
    userInfo?.customersEndPincode ? userInfo?.customersEndPincode : 600090
  );
  const [startDate, setStartDate] = useState(getCurrentDate());

  const [endDate, setEndDate] = useState(getCurrentDate());

  const [getApiQuery, setGetApiQuery] = useState(
    `?createdAt[gte]=${startDate}&createdAt[lte]=${endDate}T23:59:59.000Z&sort=createdAt`
  );
  const [todaysVisitorsCount, setTodaysVisitorsCount] = useState("");

  const dispatch = useDispatch();

  let {
    data: allGyms,
    isLoading,
    error,
    refetch,
  } = useGetAllGymsQuery({
    token: userInfo?.token,
    query: getApiQuery,
  });

  const [updateCustomer] = useUpdateCustomerMutation();

  useEffect(() => {
    if (gymName?.length > 0) {
      setGymNamePresent(false);
    }

    if (mobileNumber?.toString().length === 10 && Number(mobileNumber) > 0) {
      setMobileNumberPresent(false);
    }

    if (status === "Joined")
      if (gymId?.toString().length === 4 && Number(gymId) > 0) {
        setGymIdPresent(false);
      }

    // if (pincode?.toString().length === 6 && Number(pincode) > 0) {
    //   setPincodePresent(false);
    // }

    if (contactedDate <= getCurrentDate()) {
      setContactedDatePresent(false);
    }

    if (status !== undefined || status?.length > 0) {
      setStatusPresent(false);
    }

    // if (
    //   reachedThrough === "Not Contacted Yet" ||
    //   reachedThrough === "WhatsApp" ||
    //   reachedThrough === "Call"
    // ) {
    //   setReachedThroughPresent(false);
    // }

    if (notes?.length < 1000) {
      setNotesPresent(false);
    }
  }, [
    contactedDate,
    gymId,
    gymName?.length,
    mobileNumber,
    notes,
    pincode,
    reachedThrough,
    status,
  ]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (gymName?.length < 1 || gymName?.length > 100) {
      setGymNamePresent(true);
      fieldValidationSuccess = false;
    }
    if (mobileNumber?.toString().length !== 10) {
      setMobileNumberPresent(true);
      fieldValidationSuccess = false;
    }
    if (status === "Joined")
      if (gymId?.toString().length !== 4 || gymId <= 1000) {
        setGymIdPresent(true);
        fieldValidationSuccess = false;
      }

    // if (pincode?.toString().length !== 6) {
    //   setPincodePresent(true);
    //   fieldValidationSuccess = false;
    // }

    if (contactedDate?.split("T")[0] > getCurrentDate()) {
      setContactedDatePresent(true);
      fieldValidationSuccess = false;
    }

    if (notes?.length > 1000) {
      setNotesPresent(true);
      fieldValidationSuccess = false;
    }
    if (status?.length < 1) {
      setStatusPresent(true);
      fieldValidationSuccess = false;
    }

    // if (
    //   reachedThrough !== "Not Contacted Yet" &&
    //   reachedThrough !== "WhatsApp" &&
    //   reachedThrough !== "Call"
    // ) {
    //   setReachedThroughPresent(true);
    //   fieldValidationSuccess = false;
    // }

    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid Visitor details"
      );
    return fieldValidationSuccess;
  };

  const submitEdit = async () => {
    try {
      await updateCustomer({
        _id: editEnabled,
        token: userInfo?.token,
        payload: {
          gymName,
          gymId,
          gymLocation,
          mobileNumber,
          secondaryNumber,
          pincode,
          contactedDate: contactedDate ? contactedDate : getCurrentDate(),
          reachedThrough,
          notes,
          status,
        },
      }).unwrap();
      setGymName("");
      setGymId();
      setGymLocation("");
      setPincode();
      setSecondaryNumber();
      setEditEnabled("");
      setContactedDate(getCurrentDate());
      setStatus("");
      setMobileNumber();
      setReachedThrough("");
      setNotes("");
      toast.success("Customer Details updated");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };

  const hanbdleSubmit = async (user) => {
    if (await verifyInputFeilds()) setModalShow(true);
  };

  const handleGetCustomers = async () => {
    if (startPincode > endPincode)
      toast.error("From Pincode can not be greater than To Pincode");
    else {
      // setEndDate(getCurrentDate() + getCurrentTime());
      if (filterSelect === "Pincode Filter")
        setGetApiQuery(
          `?pincode[gte]=${startPincode}&pincode[lte]=${endPincode}&sort=createdAt`
        );
      else
        setGetApiQuery(
          `?createdAt[gte]=${startDate}&createdAt[lte]=${endDate}T23:59:59.000Z&sort=createdAt`
        );

      dispatch(
        setCredentials({
          ...userInfo,
          customersStartPincode: startPincode,
          customersEndPincode: endPincode,
        })
      );
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch, editEnabled]);

  useEffect(() => {
    const todaysVisitCount = isLoading
      ? 0
      : error
      ? 0
      : allGyms
      ? allGyms?.length
      : 0;
    setTodaysVisitorsCount(todaysVisitCount);
  }, [setTodaysVisitorsCount, allGyms, isLoading, error]);

  const handleSelect = (e) => {
    setStatus(e.target.value);
  };

  const hanbdleCancelEdit = () => {
    setEditEnabled(false);
  };

  return (
    <>
      <h5>Customer details</h5>
      <VerticallyCenteredModal
        title="Are you sure want to submit this ?"
        show={modalShow}
        size="sm"
        onHide={() => setModalShow(false)}
        onSubmit={submitEdit}
        setEdit={setEditEnabled}
      />
      <Form.Group as={Row} className="my-2" controlId="gender">
        <Col sm="6">
          <div className="horizontal-radio-group">
            {filterSelectOption?.map((option) => (
              <Form.Check
                key={option}
                type="radio"
                label={option}
                name="genderRadio"
                id={`genderRadio-${option}`}
                value={option}
                checked={filterSelect === option}
                onChange={(e) => setFilterSelect(e.target.value)}
              />
            ))}
          </div>{" "}
        </Col>
      </Form.Group>
      <Row
        className="mb-2"
        style={{
          margin: "auto",
          width: "95%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {filterSelect === "Date Filter" ? (
          <>
            {" "}
            <Col xs={6} md="auto" sm={6}>
              <Form.Group controlId="visitorMobile">
                <Form.Control
                  type="date"
                  placeholder="2023-11-23"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                ></Form.Control>{" "}
              </Form.Group>
            </Col>
            <Col xs={6} md="auto" sm={6}>
              <Form.Group controlId="visitorMobile">
                <Form.Control
                  type="date"
                  placeholder="2023-11-27"
                  value={endDate?.split("T")[0]}
                  onChange={(e) => setEndDate(e.target.value)}
                ></Form.Control>{" "}
              </Form.Group>
            </Col>
          </>
        ) : (
          <></>
        )}
        {filterSelect === "Pincode Filter" ? (
          <>
            {" "}
            <Col xs={6} md="auto" sm={6}>
              <Form.Group controlId="visitorMobile">
                <Form.Control
                  placeholder="start pincode"
                  type="text"
                  pattern="[0-9]*" // Allow only digits
                  value={startPincode}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                    setStartPincode(value);
                  }}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col xs={6} md="auto" sm={6}>
              <Form.Group controlId="visitorMobile">
                <Form.Control
                  placeholder="end pincode"
                  type="text"
                  pattern="[0-9]*" // Allow only digits
                  value={endPincode}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                    setEndPincode(value);
                  }}
                ></Form.Control>
              </Form.Group>
            </Col>
          </>
        ) : (
          <></>
        )}

        <Col xs={6} md="auto" sm={6} className="d-flex justify-content-center">
          <Form.Group className="my-1" controlId="gymType">
            <Button
              id="filterBy"
              value={filterValue}
              onClick={handleGetCustomers}
            >
              Get Gyms
            </Button>
          </Form.Group>
        </Col>
        <Col xs={12} md="auto" sm={6}>
          <Form.Group as={Row} controlId="visitorMobile">
            <Button style={{ backgroundColor: "#27aa67" }}>
              Total Customers :<strong> {todaysVisitorsCount}</strong>
            </Button>
          </Form.Group>
        </Col>
      </Row>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error.data.error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive="sm">
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>Gym Id</th>
                <th style={{ textAlign: "left" }}>Name</th>
                {/* <th>Pincode</th> */}
                <th style={{ textAlign: "left" }}>Mobile </th>
                <th style={{ textAlign: "left" }}>Address</th>
                {/* <th>Contacted Via</th> */}
                <th style={{ textAlign: "left" }}>Joined Date</th>
                <th style={{ textAlign: "left" }}>Att</th>
                <th style={{ textAlign: "left" }}>Subs</th>
                <th style={{ textAlign: "left" }}>WhatsApp</th>
              </tr>
            </thead>
            <tbody>
              {allGyms ? (
                allGyms?.map((customer) => (
                  <>
                    {editEnabled === customer._id ? (
                      <tr>
                        <td style={{ textAlign: "left" }}>
                          <Form.Control
                            value={gymName ? gymName : customer.name}
                            type="text"
                            onChange={(e) => setGymName(e.target.value)}
                          ></Form.Control>
                          {gymNamePresent ? (
                            <FieldWarning text="Please provide gym name" />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <Form.Control
                            value={
                              gymLocation ? gymLocation : customer.gymLocation
                            }
                            type="text"
                            onChange={(e) => setGymLocation(e.target.value)}
                          ></Form.Control>
                        </td>
                        {/* <td>
                          <Form.Control
                            type="text"
                            pattern="[0-9]*" // Allow only digits
                            value={pincode ? pincode : customer.pincode}
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              ); // Remove non-numeric characters
                              setPincode(value);
                            }}
                          />
                          {pincodePresent ? (
                            <FieldWarning text="Please provide 6 digit pincode" />
                          ) : (
                            ""
                          )}
                        </td> */}
                        <td>
                          <Form.Control
                            type="text"
                            pattern="[0-9]*" // Allow only digits
                            value={
                              mobileNumber
                                ? mobileNumber
                                : customer.mobileNumber
                            }
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              ); // Remove non-numeric characters
                              setMobileNumber(value);
                            }}
                          />
                          {mobileNumberPresent ? (
                            <FieldWarning text="Please provide 10 digit mobile number" />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <Form.Control
                            type="text"
                            pattern="[0-9]*" // Allow only digits
                            value={
                              secondaryNumber
                                ? secondaryNumber
                                : customer.secondaryNumber
                            }
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              ); // Remove non-numeric characters
                              setSecondaryNumber(value);
                            }}
                          />
                          {secondaryNumberPresent ? (
                            <FieldWarning text="Please provide 10 digit mobile number" />
                          ) : (
                            ""
                          )}
                        </td>
                        {/* <td>
                          <Form.Select
                            id="searchBy"
                            value={
                              reachedThrough
                                ? reachedThrough
                                : customer.reachedThrough
                            }
                            onChange={(e) => setReachedThrough(e.target.value)}
                            style={{ height: "100%" }}
                          >
                            {reachedOptions.map((a) => (
                              <option key={a} value={a}>
                                {a}
                              </option>
                            ))}
                          </Form.Select>
                          {reachedThroughPresent ? (
                            <FieldWarning text="Please select contacted option" />
                          ) : (
                            ""
                          )}
                        </td> */}
                        <td>
                          <Form.Control
                            value={
                              customer.contactedDate
                                ? customer.contactedDate?.split("T")[0]
                                : contactedDate
                            }
                            type="date"
                            onChange={(e) => setContactedDate(e.target.value)}
                          ></Form.Control>

                          {contactedDatePresent ? (
                            <FieldWarning text="Please provide valid date in format DD/MM/YYYY" />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {statusPresent ? (
                            <FieldWarning text="Please select status" />
                          ) : (
                            ""
                          )}
                          {status === "Joined" ? (
                            <>
                              <Form.Control
                                type="text"
                                pattern="[0-9]*" // Allow only digits
                                value={gymId}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ); // Remove non-numeric characters
                                  setGymId(value);
                                }}
                              />
                              {gymIdPresent ? (
                                <FieldWarning text="Please provide 4 digit gym Id" />
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </td>

                        <td style={{ textAlign: "left", minWidth: "300px" }}>
                          <Form.Control
                            value={notes ? notes : customer.notes}
                            type="text"
                            onChange={(e) => setNotes(e.target.value)}
                          ></Form.Control>
                          {notesPresent ? (
                            <FieldWarning text="notes should not have more then 1000 chars" />
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {editEnabled ? (
                            <>
                              <button
                                onClick={hanbdleSubmit}
                                style={{
                                  borderRadius: "10%",
                                  marginLeft: "10px",
                                }}
                              >
                                <CheckLg />
                              </button>
                              <button
                                onClick={hanbdleCancelEdit}
                                style={{
                                  borderRadius: "10%",
                                  margin: "8%",
                                }}
                              >
                                <XLg />
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td style={{ textAlign: "left", maxWidth: "200px" }}>
                          {customer._id}
                        </td>
                        <td style={{ textAlign: "left" }}>{customer.name}</td>
                        {/* <td>{customer.pincode}</td> */}
                        <td style={{ textAlign: "left" }}>
                          <a href={`tel:${customer.primaryContact}`}>
                            {customer.primaryContact}
                          </a>{" "}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {customer.address}
                        </td>
                        {/* <td>{customer.reachedThrough}</td> */}
                        <td style={{ whiteSpace: "nowrap", textAlign: "left" }}>
                          {customer.createdAt
                            ? customer.createdAt?.split("T")[0]
                            : ""}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {customer.attendanceCount}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {customer.subsCount}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {customer.WsMessageCount}
                        </td>

                        {/* <td>
                          <button
                            style={{ borderRadius: "20%" }}
                            onClick={() => {
                              setGymName(customer.gymName);
                              setEditEnabled(customer._id);
                              setGymLocation(customer.gymLocation);
                              setNotes(customer.notes);
                              setMobileNumber(customer.mobileNumber);
                              setSecondaryNumber(customer.secondaryNumber);
                              // setPincode(customer.pincode);
                              setReachedThrough(customer.reachedThrough);
                              setContactedDate(customer.contactedDate);
                              setStatus(customer.status);
                            }}
                          >
                            <Pencil />
                          </button>
                        </td> */}
                      </tr>
                    )}
                  </>
                ))
              ) : (
                <Message>No visitors Available</Message>
              )}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default GymDetails;
