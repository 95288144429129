import { apiSlice } from "./apiSlice";
import { WS_MESSAGE_URL } from "../constants";

const wsMessageSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWhatsAppAds: builder.query({
      query: (data) => ({
        url: `${WS_MESSAGE_URL}/getWhatsAppAds?${
          data?.query ? data.query : ""
        }`,
        headers: {
          Authorization: `Bearer ${data?.token}`,
        },
      }),
    }),
    getWhatsAppBalance: builder.query({
      query: (data) => ({
        url: `${WS_MESSAGE_URL}/getWhatsAppBalance`,
        headers: {
          Authorization: `Bearer ${data?.token}`,
        },
      }),
    }),
    updateWhatsAppAds: builder.mutation({
      query: (data) => ({
        url: `${WS_MESSAGE_URL}/updateWhatsAppAds`,
        method: "PUT",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data?.token}`,
        },
      }),
    }),
  }),
});

export const {
  useGetWhatsAppAdsQuery,
  useGetWhatsAppBalanceQuery,
  useUpdateWhatsAppAdsMutation,
} = wsMessageSlice;
